<template>
	<div
		class="authed"
		v-if="isMobileView !== null"
	>
		<ul class="authed__menu">
			<li
				class="authed__menu-item"
				v-if="isRst && !isMobile"
			>
				<a
					:href="$store.state.user.PROFILE_URL"
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					@click="close"
				>
					Старый ЛК
				</a>
			</li>
			<li
				class="authed__menu-item"
				v-if="isRst"
			>
				<router-link
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					to="/personal/"
					@click.native="close"
				>
					Личный кабинет покупателя
				</router-link>
			</li>
			<li
				class="authed__menu-item"
				v-if="!isRst && isPersonal"
			>
				<router-link
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					:to="$store.state.user.PROFILE_URL"
					@click.native="close"
				>
					Личный кабинет
				</router-link>
			</li>
			<li
				class="authed__menu-item"
				v-if="!isRst && !isPersonal"
			>
				<a
					:href="$store.state.user.PROFILE_URL"
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					@click="close"
				>
					Личный кабинет
				</a>
			</li>
			<li
				class="authed__menu-item"
				v-if="$store.state.user.LK_ACCESS"
			>
				<router-link
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					to="/lk/"
					@click.native="close"
				>
					ЛК кухни
				</router-link>
			</li>
			<li
				class="authed__menu-item"
				v-if="$store.state.user.MODER_ACCESS"
			>
				<a
					:class="!isMobileView ? 'authed__btn' : 'mobile-menu__item mobile-menu__item_sign-in'"
					href="/moder/mealsimport/"
					@click="close"
				>
					Загрузчик блюд
				</a>
			</li>
			<li class="authed__menu-item last">
				<button
					class="authed__menu-item__btn"
					:class="!isMobileView ? 'authed__btn last' : 'mobile-menu__item mobile-menu__item_sign-in'"
					@click="logOut"
				>
					Выйти
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	data()
	{
		return {
			isMobile: !!process.env.VUE_APP_MOBILE,
			isMobileView: null,
			baseUrl: process.env.BASE_URL,
		};
	},
	computed: {
		...mapGetters({
			user: "getUser",
		}),
		isRst()
		{
			return (this.user.IS_RST_MANAGER || this.user.IS_RST_OWNER);
		},
		isPersonal()
		{
			return (this.user.PROFILE_URL && this.user.PROFILE_URL.startsWith("/personal"));
		}
	},
	methods:
		{
			...mapMutations({
				setNavigationPopup: "setNavigationPopup",
			}),
			async logOut()
			{
				this.close();

				await this.$store.dispatch("logout");

				if (window.location.pathname === this.baseUrl + "order/") window.location.reload();
			},
			close()
			{
				this.setNavigationPopup(false);
			},
		},
	mounted()
	{
		this.isMobileView = window.screen.width <= 990;
	},
};
</script>

<style lang="scss">
.authed
{
	display: flex;
	flex-direction: column;
	padding: 16px 22px;
	font-family: $mainFontNew;
}

.authed__menu
{
	padding: 0;
	margin: -16px 0;
	width: 100%;

	&-item
	{
		padding: 16px 0;
		border-bottom: 1px solid #E5E5E7;

		&.last
		{border-bottom: none;}
	}

	&-item a
	{color: $textColor;}
}

.authed__menu-item__btn
{
	border: none;
	padding: 0;
	text-align: left;
}

.authed__btn
{
	display: block;
	width: 100%;
	font: 16px/24px;
	line-height: 24px;
	font-weight: bold;
	background: url('~@/assets/img/arrow.svg') right center no-repeat;
}

@media (max-width: 990px)
{
	.authed
	{
		position: relative;
		padding: 0;
		background: #fff;
		max-width: 100%;
		flex: 1 1 100%;

		.mobile-menu__item
		{
			background-color: transparent;
			width: 100%;
			color: #3D4248;
		}

		.mobile-menu__item_sign-in
		{height: 100%;}
	}
}
</style>
